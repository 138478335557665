<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <div class="card-header">
            <h5 class="card-title">Table Template</h5>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <div class="btn-group">
              <p-button type="primary" round outline @click="resetTable()">Reset</p-button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <div>
            <vue-good-table
              :theme="tableTheme"
              ref="replaceMe"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"

              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',

                perPage: serverParams.perPage,
                setCurrentPage: serverParams.page,

                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                }"
              :sort-options="{
                enabled: true,
                initialSortBy: serverParams.sort
                }">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'


  import { mapGetters } from 'vuex'
  import * as CONSTS from '../../../consts/constants'
  var numeral = require("numeral");

  export default{
    computed: {
      ...mapGetters(['userSelectedProduction','userSelectedProductionCcy', 'gridStates', 'tableTheme']),
      productionSelected () {
        return this.$store.getters.userSelectedProduction
      }
    },
    mounted () {
      this.updateTableFilters()
    },
    created () {
      let gridState = this.gridStates[this.gridName]

      if (gridState){
        this.serverParams = gridState
      }
      this.loadData()
    },
    watch: {
      productionSelected (userSelectedProduction, oldValue) {
       this.loadData()
      }
    },
    data () {
      return {
        gridName: 'replaceMe',
        serverParams: {
          gridName: 'replaceMe',
          columnFilters: {
          },
          sort: {
            field: 'company_name', type: 'asc'
          },
          page: 1,
          perPage: 10
        },
        columns: [
          {
            field: 'company_name',
            label: 'Client',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter client',
              // filterValue: this.test
            }
          },
        {
          label: 'Actions',
          field: 'actions',
        }
        ],
        rows: []
      }
    },
    methods: {

      //
      updateTableFilters() {

        for (let i = 0; i < this.columns.length; i++) {
          const col = this.columns[i];
          if (typeof col.filterOptions !== 'undefined') {

            let filterVal = {}

            if ( (filterVal = this.serverParams.columnFilters[ this.columns[i].field ] ) ) {

              let filter = this.columns[i].filterOptions
              filter.filterValue = filterVal
              // seems bonkers having to do this
              let  filterString = JSON.stringify(filter)
              let filterObj = JSON.parse(filterString)

              this.$set(this.columns[i], 'filterOptions', filterObj )
            }
          }
        }
      },
      resetTable: function() {
        this.$refs['replaceMe'].reset()
        this.serverParams.columnFilters = []
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // table events
      onPageChange(params) {
        this.serverParams.page = params.currentPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onPerPageChange(params) {
        this.serverParams.perPage = params.currentPerPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onSortChange(params) {
        let newSort = {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
        }

        this.serverParams.sort = newSort
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onColumnFilter(params) {
        this.serverParams.columnFilters = params.columnFilters
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // end table events

      loadData: function () {
        this.$http.get( 'sales/production/bookings/' + this.userSelectedProduction )

        .then(response => {
          this.rows = response.data
        })
        .catch(error => {
          this.$notify({
            message: 'There was a problem loading the salesboard. Please try again.',
            type: 'warning'
          })
        })
      },
      handleEdit (index, row) {
      }
    }
  }
</script>
<style lang="scss">

  .tablecell {
   font-size: 14px;
 }

</style>
